<template>
    <v-card>
        <v-col cols="12">
          <h1>{{totalRecords}} Master Development(s) found for "{{query}}"</h1>
          <p v-for="(item, i) in queryData" :key="i" class="search-results-p mb-5">
            <v-card>
              <v-card-text>
                <span>
                  <a class="hidden-md-and-up" :href="'/developments-management/master-developments?development='+item.id">
                    <span class="font-weight-bold" v-html="item.commercial_title"></span>
                    <span v-if="item.hk" v-html="'<br><br>' + item.hk"></span>
                  </a>
                  <a class="hidden-sm-and-down" :href="'/developments-management/master-developments?development='+item.id" target="_blank">
                    <span class="font-weight-bold" v-html="item.commercial_title"></span>
                    <span v-if="item.hk" v-html="'<br><br>' + item.hk"></span>
                  </a>
                  <span v-if="item.description" v-html='"<br><br>" + item.description.substring(0,300)+"..."'></span>
                </span>
              </v-card-text>
            </v-card>
          </p>
          <div class="text-center">
            <v-btn @click="loadMore" color="primary" v-if="totalRecords > 15 && page !== lpage" center>Load More</v-btn>
          </div>
        </v-col>
    </v-card>
</template>
  
<script>
  import {bus} from "@/main";
  import {QUICK_SEARCH} from "@/core/services/store/users.module";
  export default {
    name: "mdQS",
    props: ['query'],
    data() {
      return {
        isQueryLoaded: false,
        queryData: [],
        page: 1,
        lpage: 1,
        totalRecords: 0,
      };
    },
    mounted() {
      this.getQueryData();
      if (this.$store.state.qsQuery.length >= 4) {
        bus.$on('qsFormUpdated', data => {
          this.queryData = []
          this.page = 1
          this.lpage = 1
          this.totalRecords = 0
          this.getQueryData();
        });
      }
      if (this.$store.state.qsQuery.length >= 4) {
        bus.$on('qsDialogOpen', data => {
          this.getQueryData();
        });
      }
      bus.$on('qsDialogClosed', data => {
        this.queryData = []
        this.page = 1
        this.lpage = 1
        this.totalRecords = 0
      });
    },
    computed: {
      
    },
    methods: {
      getQueryData() {
        this.isQueryLoaded = true
          this.$store
            .dispatch(QUICK_SEARCH, {
              q: this.query,
              page: this.page,
              type: 'md'
            })
            .then(data => {
              this.queryData = this.queryData.concat(data.records.data)
              this.isQueryLoaded = false
              this.page = data.records.current_page
              this.lpage = data.records.last_page
              this.totalRecords = data.records.total
            });
      },
      loadMore(){
        this.page++
        this.getQueryData()
      }
    }
  };
</script>