<template>
  <v-dialog style="z-index: 99999" persistent v-model="$store.state.qsDialog" fullscreen>
    <v-card>
      <v-toolbar
          dark
          color="#00004d"
      >
        <v-toolbar-title class="pl-0">
          <span>Quick Search</span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn
              icon
              dark
              @click="closeQSDialog"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text class="mt-3">
        <v-row>
          <v-col cols="12" sm="12">
            <v-form ref="qsMainForm" v-on:submit.prevent="hitQsMainFormInner">
              <v-text-field :counter="300"
                            outlined
                            dense
                            rounded
                            hide-details
                            maxlength="300"
                            v-model="$store.state.qsQuery"
                            placeholder="Search (Minimum 4 Characters)">
              </v-text-field>
            </v-form>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row>
          <v-col cols="12">
            <v-tabs v-model="activeTab" background-color="#002366" centered dark>
              <v-tab>All</v-tab>
              <v-tab>Master Developments</v-tab>
              <v-tab>Sub Developments</v-tab>
              <v-tab>Projects</v-tab>
              <v-tab>Listings</v-tab>
              <v-tab>Contacts</v-tab>
            </v-tabs>
            <v-tabs-items touchless v-model="activeTab">
              <v-tab-item><allQS :query="$store.state.qsQuery" /></v-tab-item>
              <v-tab-item><mdQS :query="$store.state.qsQuery" /></v-tab-item>
              <v-tab-item><sdQS :query="$store.state.qsQuery" /></v-tab-item>
              <v-tab-item><projectQS :query="$store.state.qsQuery" /></v-tab-item>
              <v-tab-item><listingQS :query="$store.state.qsQuery" /></v-tab-item>
              <v-tab-item><contactQS :query="$store.state.qsQuery" /></v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-row>
        <!-- <v-row v-if="totalRecords > 0">
          <v-col cols="12">
            <v-pagination
                :total-visible="15"
                color="#00004d"
                :disabled="isQueryLoaded"
                v-model="page"
                @input="getQueryData"
                :length="lpage">
            </v-pagination>
          </v-col>
        </v-row> -->
      </v-card-text>
      <v-col cols="12">
        <div v-if="errors!=undefined && errors.length >= 0"
             role="alert"
             v-bind:class="{ show: errors.length }"
             class="alert fade alert-danger"
        >
          <div class="alert-text" v-for="(error, i) in errors" :key="i">
            {{ error }}
          </div>
        </div>
      </v-col>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapState} from "vuex";
import {bus} from "@/main";
import {QUICK_SEARCH} from "@/core/services/store/users.module";
import allQS from "./inc/allQS.vue";
import mdQS from "./inc/mdQS.vue";
import sdQS from "./inc/sdQS.vue";
import projectQS from "./inc/projectQS.vue";
import listingQS from "./inc/listingQS.vue";
import contactQS from "./inc/contactQS.vue";

export default {
  components: {
    allQS,
    mdQS,
    sdQS,
    projectQS,
    listingQS,
    contactQS,
  },
  data() {
    return {
      
    };
  },
  mounted() {
    bus.$on('qsDialogOpen', data => {
      this.$store.state.qsDialog = data;
    });
  },
  computed: {
    ...mapState({
      errors: state => state.users.errors
    }),
  },
  methods: {
    hitQsMainFormInner() {
      if (this.$store.state.qsQuery.length >= 4) {
        this.activeTab = 0
        bus.$emit('qsFormUpdated', true)
      }
    },
    queryKeyUp(e) {
      this.page = 1;
      clearTimeout(this.apiTimeout)
      let that = this
      this.apiTimeout = setTimeout(function () {
        if (e.target.value.length >= 4) {
          that.isQueryLoaded = true;
          that.$store
              .dispatch(QUICK_SEARCH, {
                q: e.target.value,
                page: that.page
              })
              .then(data => {
                that.queryData = data.records.data
                that.isQueryLoaded = false
                that.page = data.records.current_page
                that.lpage = data.records.last_page
                that.totalRecords = data.records.total
              });
        }
      }, 900);
    },
    closeQSDialog(){
      this.$store.state.qsDialog = false;
      this.$store.state.qsQuery = ''
      this.activeTab = 0
      bus.$emit('qsDialogClosed')
    }
  }
};
</script>